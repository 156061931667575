import React from "react";
import styled from "styled-components";
import Banner from "../components/banner";

const Home = () => {
  return (
    <HomeScreen>
      <Banner />
    </HomeScreen>
  );
};
export default Home;

const HomeScreen = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
`;
