import React from "react";
import styled from "styled-components";
import Card from "../components/projectCard";

const projects = () => {
  return (
    <ProjectsContainer>
      <h2 className="title">Projects</h2>
      <div className="all-projects">
        <Card />
        <Card />
      </div>
    </ProjectsContainer>
  );
};

export default projects;

const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  max-width: 100vw;
  margin: 0;
  gap: 30px;

  .title {
    margin-top: 20px;
    font-weight: bold;
    font-size: 50px;
    margin-left: 15px;
    text-align: center;
    margin-bottom: 50px;
  }
  .all-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100vw;
    gap:30px;
  }
`;
