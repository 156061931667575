import React, { useState } from "react";
import styled from "styled-components";

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  return (
    <FormContainer>
      <form className="form-group">
        <label className="input-label"> Email</label>
        <input
          type="email"
          value={email}
          className="input"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <label className="input-label"> Phone</label>
        <input
          value={phone}
          className="input"
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
        <label className="input-label"> Message</label>
        <input
          value={message}
          className="input"
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />

        <button className="submit-contact" type="submit">
          Send
        </button>
      </form>
    </FormContainer>
  );
};

export default ContactForm;

const FormContainer = styled.div`
  .form-group {
    display: flex;
    flex-direction: column;
    margin: 10px;
    gap: 20px;
    padding: 20px 0;
  }
  .input {
    border: none;

    border: 1px solid lightgrey;
    border-radius: 4px;
    height: 35px;
  }
  .input-label {
    background: none;
  }
  .input:focus {
    padding-left: 10px;
    font-weight: bold;
    outline: none;
    flex-wrap: wrap;
  }

  .input:placeholder-shown {
    padding-left: 10px;
    font-size: 15px;
  }
  .submit-contact {
    background-color: #030821;
    min-height: 35px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    margin: 0 50px;
    color: #f7f9fb;
  }
`;
