import React from "react";
import styled from "styled-components";
import noote from "../media/noote.png";

const projectCard = () => {
  return (
    <Card>
      <img className="project-logo" src={noote} alt="" />
      <p className="desc">
        Noote is an online daily planner, which help us stay organized and
        access it at anytime whereas using a bullet journal or a traditional
        planner struggles
      </p>
      <div className="skill-tags">
        <div className="tag">NodeJS</div>
        <div className="tag">React</div>
        <div className="tag">Express</div>
        <div className="tag">GitHub</div>
        <div className="tag">MongoDB</div>
        <div className="tag">UI & UX</div>
      </div>

      <div className="buttons">
        <button className="btn">Open</button>
        <button className="btn">Code</button>
        <button className="btn">Demo</button>
      </div>
    </Card>
  );
};

export default projectCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 7px;
  width: 700px;
  gap: 30px;
  margin: 10px auto;
  padding: 10px;
  /* box-shadow: -5px 5px #585b70; */
  border-bottom: 1.5px solid lightgrey;

  .project-logo {
    width: 150px;
  }
  .desc {
  }

  .skill-tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .btn {
    border: none;
    font-size: medium;
    font-weight: bold;
  }
  .tag {
    color: #f7f9fb;
    background-color: #030821;
    border-radius: 7px;
    padding: 7px;
    font-size: small;
  }

  @media only screen and (max-width: 600px) {
    & {
      min-width: 85vw;
      max-width: 85vw;
      margin: 0 auto;
    }
  }
`;
