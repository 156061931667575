import React from "react";
import styled from "styled-components";
import Form from "../components/contactForm";

const contact = () => {
  return (
    <ContactContainer>
      <h2 className="title">Contact</h2>

      <div className="contact-card">
        <Form />
      </div>
    </ContactContainer>
  );
};

export default contact;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  max-width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: #f7f9fb;
  margin: 0;
  gap: 20px;

  .contact-card {
    border-radius: 7px;
    width: 500px;
    /* box-shadow: 5px 5px #585b70; */
  }
  .title {
    font-weight: bold;
    font-size: 50px;
    text-align: center;
  }

  .contact-card:hover {
    box-shadow: none;
  }

  .contact-desc {
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    & {
      min-width: 85vw;
      max-width: 85vw;
      margin: 0 auto;
    }
    .contact-card {
      width: 100%;
    }
  }
`;
