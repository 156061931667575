import React, { useState } from "react";
import styled from "styled-components";
import { TiThMenu } from "react-icons/ti";

const Navigation = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen((prev) => !prev);
  };
  return (
    <NavBar>
      <button className="nav-btn" onClick={toggleNav}>
        <TiThMenu />
      </button>
      {isNavOpen && (
        <div className="menu" href="/contact">
          <a className="nav" href="/">
            Home
          </a>
          <a className="nav" href="/contact">
            Story
          </a>
          <a className="nav" href="/contact">
            Contact
          </a>
          <a className="nav" href="/projects">
            Projects
          </a>
          <a className="nav" href="/contact">
            Work
          </a>
        </div>
      )}
    </NavBar>
  );
};

export default Navigation;

const NavBar = styled.div`
  margin-top: 0;
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  z-index: 100;

  .nav-btn {
    background: none;
    border: none;
    font-size: 20px;
    margin-top: 20px;
    cursor: pointer;
  }
  .menu {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 90vh;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .nav {
    font-size: 35px;
    font-weight: 700;
    cursor: pointer;
    transition: transform 0.2s;
    text-decoration: none;
  }
  .nav:hover {
    transform: scaleX(1.2);
  }
`;
