import React from "react";
import styled from "styled-components";
import { FaLinkedinIn } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { AiFillInstagram, AiTwotonePhone } from "react-icons/ai";
import Gif from "../media/home.gif";

const banner = () => {
  return (
    <BannerContainer>
      <img src={Gif} alt="" className="gif" />
      <h2 className="title">Sabtha Rishi</h2>
      <p className="description">Crafting better products</p>
      <div className="contact-details">
        <div className="icon">
          <FaLinkedinIn />
        </div>
        <div className="icon">
          <IoLogoWhatsapp />
        </div>
        <div className="icon">
          <AiFillInstagram />
        </div>
        <div className="icon">
          <AiTwotonePhone />
        </div>
      </div>
    </BannerContainer>
  );
};

export default banner;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 90vh;

  .title {
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    margin: 0;
  }

  .contact-details {
    display: flex;
    flex-direction: row;
    color: #4183d9;
    gap: 10px;
    margin-top: 50px;
    cursor: pointer;
  }
  .description {
    opacity: 70%;
  }
  .icon {
    transition: transform 0.2s;
  }

  .icon:hover {
    transform: scale(1.4);
    color: #4994f5;
  }
  .gif {
    width: 150px;
  }
`;
